<template>
  <div v-if="leadCount">
    Opgelet: er {{ leadCount > 1 ? 'zijn' : 'is' }} momenteel nog
    <router-link
      :to="{ name: 'PropertyLeads', query: { status: [0, 1] } }"
      target="_blank"
      class="link tw-font-semibold"
    >
      {{ leadCount }} actieve lead{{ leadCount > 1 ? 's' : '' }}.
    </router-link>
    <button
      v-if="!leadNotificationData"
      type="button"
      class="btn btn-xs btn-default tw-mt-2 tw-block"
      :disabled="sendingOpenLeadsNotification"
      @click="sendOpenLeadsNotification"
    >
      <span v-if="!sendingOpenLeadsNotification">
        <i class="fa fa-bell tw-mr-1" />
        IAM-notificatie sturen naar makelaar
      </span>
      <span v-else>
        <i class="fal fa-spinner-third fa-spin tw-mr-1" />
        Notificatie aanmaken ...
      </span>
    </button>
    <div v-else class="tw-mt-2 tw-pb-0">
      <p>
        <span><i class="fas fa-check-circle tw-mr-1 tw-text-green-500" />{{ leadNotificationData.collaborator }} ontving onderstaande notificatie:</span>
        <br />
        <span class="tw-italic">{{ leadNotificationData.description }}</span>
      </p>
    </div>
    <template v-if="showBreakLeadsButton">
      <button
        v-if="!leadsBroken"
        type="button"
        :disabled="breakingLeads"
        class="btn btn-sm btn-primary tw-mt-4"
        @click="breakAllLeads"
      >
        <i :class="['fa tw-mr-1', breakingLeads ? 'fa-spinner-third fa-spin' : '']" />
        {{ breakingLeads ? 'Leads worden gebreakt ...' : 'Alle leads breaken' }}
      </button>
    </template>
  </div>
  <div v-else>
    <p v-if="leadsBroken">
      <span><i class="fas fa-check-circle tw-mr-1 tw-text-green-500" />De actieve leads werden op break gezet.</span>
    </p>
    <p v-else>Er zijn geen actieve leads.</p>
  </div>
</template>

<script>
import { errorModal } from '@/modalMessages'
import { poll } from '@/utils/helpers'

import { breakLeads, getLeads, sendOpenLeadsNotification } from '@/services/sales'
import { getJobStatus } from '@/services/apiService'

export default {
  name: 'WizardLeadCount',
  props: {
    propertyId: {
      type: [String, Number],
      required: true
    },
    showBreakLeadsButton: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.fetchLeadCount()
  },
  data () {
    return {
      leadCount: null,
      sendingOpenLeadsNotification: false,
      leadNotificationData: null,
      breakingLeads: false,
      leadsBroken: false
    }
  },
  methods: {
    async fetchLeadCount () {
      const params = { property: this.propertyId, status: '0,1', page_size: 1 }
      const response = await getLeads({ params })
      this.leadCount = response.data.count
      return response
    },
    async sendOpenLeadsNotification () {
      try {
        this.sendingLeadsNotification = true
        const response = await sendOpenLeadsNotification({ property_id: this.propertyId })
        this.leadNotificationData = response.data
        return response
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het versturen van de notificatie.')
      } finally {
        this.sendingLeadsNotification = false
      }
    },
    async breakAllLeads () {
      try {
        this.breakingLeads = true
        const params = { property: this.propertyId, status: '0,1', page_size: 1000 }
        const leadsResponse = await getLeads({ params })
        const leadIds = leadsResponse.data.results.map(leadObj => leadObj.id)
        const breakResponse = await breakLeads({ leads: leadIds })
        await poll(breakResponse?.data?.job_id, getJobStatus, 1000)
        await this.fetchLeadCount()
        this.leadsBroken = true
        return breakResponse
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het breaken van de leads.')
      } finally {
        this.breakingLeads = false
      }
    }
  }
}
</script>
