<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="rentalAgreementStep2"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="moveToStep3"
  >
    <EntityContactsFormPartial
      ref="entityOwners"
      contact-role="owner"
      :form-values="values"
      :property-id="propertyId"
      :show-disclaimer="false"
      :contact-validations="[
        ['bail'],
        ['required'],
        ['min', 1, 'length']
      ]"
    />
    <EntityContactsFormPartial
      ref="entityRenters"
      contact-role="renter"
      :form-values="values"
      :property-id="propertyId"
      :show-disclaimer="false"
      :contact-validations="[
        ['bail'],
        ['required'],
        ['min', 1, 'length']
      ]"
      class="tw-mt-4 tw-mb-8"
    />
    <FormulateErrors class="tw-text-right" />

    <div class="tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
      <FormulateInput
        type="button"
        :input-class="['tw-bg-gray-500']"
        outer-class="tw-my-0"
        @click="goBackToStep1"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige stap
      </FormulateInput>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        outer-class="tw-my-0"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende stap
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { currency } from '@/utils/helpers'
import EntityContactsFormPartial from '@/components/properties/EntityContactsFormPartial'

export default {
  name: 'WizardSalesAgreementStep2',
  components: {
    EntityContactsFormPartial
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    }
  },
  constants: {
    CURRENT_STEP: 2
  },
  data () {
    return {
      values: {}
    }
  },
  methods: {
    currency,
    goBackToStep1 () {
      this.$emit('go-back', this.CURRENT_STEP)
    },
    async moveToStep3 (data) {
      const owners = await this.$refs.entityOwners.submit()
      const renters = await this.$refs.entityRenters.submit()
      this.$emit('finished', data, this.CURRENT_STEP)
      return [owners, renters]
    }
  }
}
</script>
