<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="salesAgreementStep1"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="moveToStep2"
  >
    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Dossier</h2>
      <template v-if="initialValues.transaction_type === 4">
        <p class="tw-italic">
          Opgelet, dit is een overnamedossier. Opdat de juiste btw-code zou toegepast worden op de ereloonfactuur, is het belangrijk dat je hieronder aanduidt of het om een overname handelsfonds, dan wel om overname aandelen gaat.
        </p>
        <FormulateInput
          type="radio"
          name="sale_with_shares"
          :value="values.sale_with_shares"
          :options="{
            2: 'Overname handelsfonds (21% btw op ereloon)',
            1: 'Overname aandelen (0% btw op ereloon)'
          }"
          outer-class="tw-mb-8"
        />
      </template>
      <FormulateInput
        v-if="hasCoBroker"
        type="radio"
        name="sold_by"
        :options="{
          dewaele: 'Verkoop door Dewaele',
          'co-broker': 'Verkoop door co-makelaar'
        }"
        debounce
        validation="required"
        validation-name="Dit veld"
        outer-class="tw-mb-8"
        @input="soldByChanged"
      />
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="date"
          name="agreement_signature_date"
          label="Datum ondertekening"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          :show-required-label="true"
          data-lpignore="true"
          outer-class="tw-my-0"
        />
        <FormulateInput
          type="date"
          name="deed_date"
          label="Voorgestelde aktedatum"
          placeholder="YYYY-MM-DD"
          :min="values.agreement_signature_date"
          :validation="[
            ['bail'],
            ['required'],
            ['date', 'YYYY-MM-DD'],
            ['after', values.agreement_signature_date]
          ]"
          :show-required-label="true"
          data-lpignore="true"
          outer-class="tw-my-0"
        />
      </div>
    </div>

    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Prijs</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <div>
          <span class="formulate-label">Oorspronkelijke inkoopprijs</span>
          <div
            v-if="transactionPriceIn"
            class="tw-mt-4 tw-flex tw-gap-x-6 tw-text-base tw-items-baseline"
          >
            {{ currency(transactionPriceIn) }}
            <template v-if="values.sales_value_adjustment_amount">
              <i class="far fa-arrow-right" />
              {{ currency(newPrice) }}
            </template>
          </div>
        </div>
        <FormulateInput
          type="number"
          name="transaction_price_out"
          :label="salesOrAcquisitionPriceLabelCapitalized"
          :placeholder="salesOrAcquisitionPriceLabelCapitalized"
          lang="nl"
          step="0.01"
          min="0"
          value="0"
          debounce
          validation="bail|required|number|min:0"
          data-lpignore="true"
          outer-class="tw-my-0"
          @input="handleTransactionPriceOutChange"
        />
      </div>

      <template v-if="Math.abs(priceDifference) > 5000">
        <div v-if="Math.sign(priceDifference) < 0" class="tw-my-8">
          <h3 class="tw-font-semibold tw-text-error tw-text-sm">
            Opgelet! De {{ salesOrAcquisitionPriceLabel }} is lager dan de vooropgestelde prijs.
          </h3>
          <FormulateInput
            key="sales_value_adjustment_type_1"
            type="radio"
            name="sales_value_adjustment_type"
            label="Wijziging onroerend goed"
            value="1"
            checked="true"
          />
          <FormulateInput
            v-if="values.sales_value_adjustment_type === '1'"
            key="sales_value_adjustment_amount_1"
            type="number"
            name="sales_value_adjustment_amount"
            placeholder="Prijs wijziging onroerend goed (€)"
            lang="nl"
            step="0.01"
            min="0"
            validation="bail|required|number|min:0"
            validation-name="Prijs wijziging onroerend goed"
            :show-required-label="true"
            data-lpignore="true"
            label-class="tw-my-0"
            :input-class="['tw-max-w-sm']"
          />
          <FormulateInput
            key="sales_value_adjustment_type_2"
            type="radio"
            name="sales_value_adjustment_type"
            label="Onderhandelingsmarge"
            value="2"
          />
        </div>

        <div v-else class="tw-my-8">
          <h3 class="tw-font-semibold tw-text-error tw-text-sm">
            Opgelet! De {{ salesOrAcquisitionPriceLabel }} is hoger dan de vooropgestelde prijs.
          </h3>
          <FormulateInput
            key="sales_value_adjustment_type_3"
            type="radio"
            name="sales_value_adjustment_type"
            label="Bijkomend onroerend goed"
            value="3"
            checked="true"
          />
          <FormulateInput
            v-if="values.sales_value_adjustment_type === '3'"
            key="sales_value_adjustment_amount_2"
            type="number"
            name="sales_value_adjustment_amount"
            placeholder="Prijs bijkomend onroerend goed (€)"
            validation-name="Prijs bijkomend onroerend goed"
            lang="nl"
            step="0.01"
            min="0"
            validation="bail|required|number|min:0"
            :show-required-label="true"
            data-lpignore="true"
            label-class="tw-my-0"
            :input-class="['tw-max-w-sm']"
            :element-class="['tw-flex tw-items-center']"
          >
            <template #suffix>
              <!-- Only when price is higher -->
              <Tooltip class="tw-inline">
                <i class="fas fa-info-circle tw-ml-2" />
                <template #popper>
                  Dit is de oorspronkelijke publicatiewaarde van het bijkomende goed.
                </template>
              </Tooltip>
            </template>
          </FormulateInput>
          <FormulateInput
            key="sales_value_adjustment_type_4"
            type="radio"
            name="sales_value_adjustment_type"
            label="Commercieel succes"
            value="4"
          />
        </div>

        <FormulateInput
          type="textarea"
          name="sales_value_adjustment_remark"
          label="Opmerking"
          placeholder="Opmerking"
          validation="required:trim"
          :show-required-label="true"
          :input-class="['tw-h-24']"
        />
      </template>
    </div>

    <div
      :class="[
        'tw-mb-8 tw-pb-4 tw-border-b',
        { 'tw-opacity-50': soldByCoBroker }
      ]"
    >
      <h2>Voorschot</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateInput
          type="radio"
          name="advance_payment_account"
          label="Betaling voorschot op rekening"
          value="1"
          :options="{
            1: 'Dewaele',
            2: 'Notaris/derde'
          }"
          :disabled="soldByCoBroker"
          outer-class="tw-my-0"
          @input="advancePaymentAccountChanged"
        />
        <!-- Do not allow deducting sales fees from advance payment in case it was not made to Dewaele -->
        <FormulateInput
          type="checkbox"
          name="should_deduct_sales_fee_from_advance_payment"
          label="Afhouden ereloon van voorschot"
          :disabled="parseInt(values.advance_payment_account) !== 1 || soldByCoBroker"
        />
      </div>
      <div class="tw-grid tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="number"
          name="advance_payment"
          label="Voorschot"
          placeholder="Voorschot"
          value="0"
          lang="nl"
          step="0.01"
          min="0"
          :validation="[
            ['bail'],
            [values.sold_by !== 'co-broker' ? 'required' : ''],
            ['number'],
            ['min', 0]
          ]"
          :show-required-label="values.sold_by !== 'co-broker'"
          :disabled="soldByCoBroker"
          data-lpignore="true"
          outer-class="tw-my-0"
        />
        <FormulateInput
          v-show="parseInt(values.advance_payment_account) === 1 && !soldByCoBroker"
          type="select"
          name="third_party_account"
          label="Derdenrekening"
          placeholder="Derdenrekening"
          :validation="parseInt(values.advance_payment_account) === 1 && !soldByCoBroker ? 'required' : ''"
          :options="thirdPartyAccounts"
          :disabled="thirdPartyAccounts.length <= 1"
          outer-class="tw-my-0 tw-col-span-2"
        />
      </div>
    </div>

    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Ereloon</h2>
      <div
        :class="[
          'tw-mt-4 tw-grid md:tw-grid-cols-3 tw-gap-4',
          { 'tw-opacity-50': soldByCoBroker }
        ]"
      >
        <div>
          <span class="formulate-label">Afgesproken ereloon bij opdracht</span>
          <div
            v-if="values.transaction_data[0]"
            class="tw-mt-4 tw-flex tw-gap-x-6 tw-text-base tw-items-baseline"
          >
            <span>
              {{ currency(newIntakeCommission) }}
            </span>
            <span v-if="values.transaction_data[0].commission_percentage_in">
              {{ values.transaction_data[0].commission_percentage_in }}%
            </span>
            <span v-else>
              Fix
            </span>
          </div>
        </div>

        <FormulateInput
          type="radio"
          name="commission_discount"
          label="Commerciële toegift:"
          :options="commissionDiscountOptions"
          :disabled="soldByCoBroker"
          outer-class="tw-my-0"
        />

        <FormulateInput
          v-if="values.commission_discount === 'fix'"
          key="commission_fix_discount"
          type="number"
          name="commission_fix_discount"
          label="Korting"
          placeholder="Korting (€)"
          lang="nl"
          step="0.01"
          min="0"
          debounce
          validation="bail|number|min:0"
          :disabled="soldByCoBroker"
          data-lpignore="true"
          outer-class="tw-my-0"
          @input="handleCommissionDiscountChange"
        />

        <FormulateInput
          v-if="values.commission_discount === 'percentage'"
          key="commission_percentage_out"
          type="number"
          name="commission_percentage_out"
          label="Nieuw percentage"
          placeholder="Nieuw percentage (%)"
          lang="nl"
          step="0.01"
          min="0"
          debounce
          validation="bail|number|min:0"
          :disabled="soldByCoBroker"
          data-lpignore="true"
          outer-class="tw-my-0"
          @input="handleCommissionDiscountPercentageChange"
        />

      </div>

      <div
        :class="[
          'tw-mb-4 tw-grid tw-grid-cols-3 tw-gap-4'
        ]"
      >
        <div class="tw-inline">
          <div class="tw-mt-4 tw-gap-x-6 tw-text-base tw-items-baseline">
            <div class="formulate-label">Te factureren ereloon</div>
            <div :class="['tw-mt-4', overriddenCommissionAfterDiscount ? 'tw-line-through' : '']">
              {{ currency(commissionOutWithoutDiscount) }}
              <template v-if="commissionOutWithoutDiscount !== commissionAfterDiscount">
                <i class="far fa-arrow-right" />
                {{ currency(commissionAfterDiscount) }}
              </template>
            </div>
            <div v-if="overriddenCommissionAfterDiscount" class="tw-mt-4">
              Min. commissie: {{ currency(overriddenCommissionAfterDiscount) }}
            </div>
          </div>
        </div>
        <div v-if="values.transaction_data[0].commission_percentage_in && commissionAfterDiscount < ALLOW_COMMISSION_OVERRIDE_FOR_AMOUNT" class="tw-inline !tw-opacity-100">
          <FormulateInput
            label="Minimale commissie ingeven"
            type="checkbox"
            name="override_commission"
            @change="handleCommissionOverrideToggle"
          />
          <FormulateInput
            v-if="values.override_commission"
            type="number"
            min="0"
            step="0.01"
            name="overridden_commission"
            placeholder="Geef de minimale commissie in"
          />
        </div>
      </div>

      <div v-if="values.sold_by" class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          id="co_mandate_collaborator_percentage_out"
          type="number"
          name="co_mandate_collaborator_percentage_out"
          label="Verkopende makelaar"
          placeholder="Percentage"
          validation="bail|required|number|min:0|validMandatePercentage"
          :validation-rules="{ validMandatePercentage }"
          :validation-messages="{
            validMandatePercentage: `De som van percentages moet ${requiredPercentageSum}% zijn`
          }"
          step=".01"
          min="0"
          lang="nl"
          outer-class="tw-my-4"
          :element-class="['tw-flex tw-items-center']"
          :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
        >
          <div slot="suffix" class="input-unit">
            <i class="fas fa-percent" />
          </div>
        </FormulateInput>
        <FormulateInput
          id="co_mandate_other_percentage_out"
          type="number"
          name="co_mandate_other_percentage_out"
          label="Niet-verkopende makelaar"
          placeholder="Percentage"
          validation="bail|required|number|min:0|validMandatePercentage"
          :validation-rules="{ validMandatePercentage }"
          :validation-messages="{
            validMandatePercentage: `De som van percentages moet ${requiredPercentageSum}% zijn`
          }"
          step=".01"
          min="0"
          lang="nl"
          outer-class="tw-my-4"
          :element-class="['tw-flex tw-items-center']"
          :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
        >
          <div slot="suffix" class="input-unit">
            <i class="fas fa-percent" />
          </div>
        </FormulateInput>
      </div>

      <div v-show="values.sold_by" class="tw-mb-4">
         <ul class="tw-list-disc tw-ml-4">
          <li>
            Verkopende makelaar: {{ currency(commissionAmountCollaborator) }}
          </li>
          <li>
            Niet-verkopende makelaar: {{ currency(commissionAmountOther) }}
          </li>
        </ul>
        <p class="tw-mt-4 tw-font-bold">In dit dossier is {{ values.sold_by === 'dewaele' ? 'Dewaele' : 'de co-makelaar'}} de verkopende makelaar.</p>
      </div>

      <p v-show="values.sold_by !== 'co-broker'" class="tw-italic">
        Opgelet: commerciële tegemoetkoming op documenten mag hier niet ingegeven worden.
      </p>
    </div>

    <div v-if="!production" class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Betrokken kantoren en poules</h2>
      <TransactionInvolvementData
        ref="transactionInvolvements"
        context="salesAgreement"
        :property-id="propertyId"
      />
    </div>

    <div class="tw-mb-8 tw-pb-4 tw-border-b">
      <h2>Opschortende voorwaarde</h2>
      <FormulateInput
        type="group"
        name="transaction_data"
      >
        <template #default="{ index, model }">
          <FormulateInput
            type="checkbox"
            name="ovab"
            label="Opschortende voorwaarde"
          />

          <div v-if="hasSuspensiveCondition" class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
            <FormulateInput
              type="select"
              name="ovab_type"
              label="Type"
              placeholder="Selecteer een voorwaarde"
              validation="required"
              :options="suspensiveConditions"
              outer-class="tw-my-0"
            />
            <FormulateInput
              type="date"
              name="ovab_date"
              label="Opvolgdatum"
              placeholder="YYYY-MM-DD"
              validation="bail|required|date:YYYY-MM-DD"
              data-lpignore="true"
              outer-class="tw-my-0"
            />
            <FormulateInput
              v-if="model[index]"
              type="date"
              name="ovab_due_date"
              label="Vervaldatum"
              placeholder="YYYY-MM-DD"
              :min="model[index].ovab_date"
              :validation="[
                ['bail'],
                ['required'],
                ['date', 'YYYY-MM-DD'],
                ['after', model[index].ovab_date]
              ]"
              data-lpignore="true"
              outer-class="tw-my-0"
            />
          </div>

          <FormulateInput
            v-if="hasSuspensiveCondition"
            type="textarea"
            name="ovab_information"
            label="Opmerking"
            placeholder="Opmerking"
            validation="required"
            :input-class="['tw-h-24']"
          />
        </template>
      </FormulateInput>
    </div>

    <FormulateInput
      type="file"
      name="sales_agreement_file"
      :label="`${agreementLabel} uploaden`"
      :uploader="uploadFile"
      :upload-area-mask-class="['tw-h-10 tw-mt-1 !tw-rounded-sm !tw-border-0.5']"
      :file-class="['tw-h-10 !tw-mt-1']"
      element-class="formulate-input-element formulate-input-element--file"
      wrapper-class="formulate-input-wrapper"
      outer-class="formulate-input !tw-my-0"
    >
      <template #file="context">
        <BaseFileUploadDisplay
          v-bind="context"
          :show-remove-file="true"
        />
      </template>
    </FormulateInput>

    <FormulateErrors class="tw-text-right" />

    <div class="tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
      <FormulateInput
        type="button"
        :input-class="['tw-bg-gray-500']"
        outer-class="tw-my-0"
        @click="cancelDossier"
      >
        <i
          :class="[
            'fas tw-mr-2 fa-arrow-left'
          ]"
        />
        Verkoop annuleren
      </FormulateInput>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        outer-class="tw-my-0"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende stap
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { mapActions } from 'vuex'
import { Tooltip } from 'floating-vue'
import { currency, parseFileObj, percentage } from '@/utils/helpers'

import TransactionInvolvementData from '@/components/transactions/TransactionInvolvementData'

import {
  uploadFile,
  createPropertyFile,
  createPropertyFileFolder
} from '@/services/properties'

export default {
  name: 'WizardSalesAgreementStep1',
  components: {
    Tooltip,
    TransactionInvolvementData
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    initialValues: {
      type: Object,
      required: true
    },
    suspensiveConditions: {
      type: Array,
      required: true
    },
    thirdPartyAccounts: {
      type: Array,
      required: true
    }
  },
  constants: {
    CURRENT_STEP: 1,
    // If a commission is percentage-based and the commission amount is lower than 6500
    // It should be possible to override the commission manually
    ALLOW_COMMISSION_OVERRIDE_FOR_AMOUNT: 6500
  },
  data () {
    return {
      values: {},
      folderId: null,
      cancellingDossier: false,
      changedPercentage: null
    }
  },
  computed: {
    production () {
      return process.env.VUE_APP_ENVIRONMENT === 'prod'
    },
    hasCoBroker () {
      return this.values.transaction_data[0]?.mandate_type === 'co_mandate'
    },
    soldByCoBroker () {
      return this.values.sold_by === 'co-broker'
    },
    coMandatePercentageType () {
      if ([undefined, null].includes(this.values.co_mandate_percentage_type_out)) return ''
      return this.values.co_mandate_percentage_type_out === 0 ? 'commission_amount_split' : 'commission_percentage_split'
    },
    transactionPriceIn () {
      return parseFloat(this.values.transaction_data[0]?.transaction_price_in)
    },
    priceDifference () {
      const difference = parseFloat(this.values.transaction_price_out) - parseFloat(this.transactionPriceIn)
      if (difference === 0) return null
      return difference
    },
    newPrice () {
      const adjustment = parseFloat(this.values.sales_value_adjustment_amount)
      if (adjustment) {
        const price = Math.sign(this.priceDifference) === -1
          ? this.transactionPriceIn - adjustment
          : this.transactionPriceIn + adjustment
        return parseFloat(price)
      }
      return this.transactionPriceIn
    },
    commissionFixIn () {
      if (this.values.sold_by) return parseFloat(this.values.transaction_data?.[0].commission_fix_in) + parseFloat(this.values.transaction_data?.[0].commission_external_amount_in)
      return parseFloat(this.values.transaction_data?.[0].commission_fix_in)
    },
    newIntakeCommission () {
      const commissionPercentageIn = parseFloat(this.values.transaction_data?.[0].commission_percentage_in)
      if (!commissionPercentageIn) return this.commissionFixIn
      return this.newPrice * (commissionPercentageIn / 100)
    },
    commissionDiscountOptions () {
      const options = {}
      if (this.values.transaction_data?.[0]?.commission_percentage_in && !this.values.override_commission) {
        options.percentage = 'Nieuw percentage (% ereloon)'
      }
      options.fix = 'Korting (€)'
      return options
    },
    commissionOutWithoutDiscount () {
      const commissionPercentageIn = parseFloat(this.values.transaction_data?.[0].commission_percentage_in)
      const transactionPriceOut = parseFloat(this.values.transaction_price_out)
      const commissionFixIn = this.commissionFixIn
      const initialCommission = commissionPercentageIn
        ? transactionPriceOut * (commissionPercentageIn / 100)
        : commissionFixIn
      return initialCommission
    },
    commissionDiscount () {
      const {
        commission_fix_discount,
        commission_percentage_out,
        transaction_price_out
      } = this.values

      const initialCommission = this.commissionOutWithoutDiscount
      if (commission_percentage_out) {
        const commissionAfterDiscount = parseFloat(transaction_price_out) * (parseFloat(commission_percentage_out) / 100)
        return initialCommission - commissionAfterDiscount
      }
      if (commission_fix_discount) return parseFloat(commission_fix_discount)
      return 0
    },
    commissionAfterDiscount () {
      return this.commissionOutWithoutDiscount - this.commissionDiscount
    },
    overriddenCommissionAfterDiscount () {
      if (!this.values.overridden_commission) return null
      return this.values.overridden_commission - this.commissionDiscount
    },
    commissionAfterDiscountForCalculations () {
      if (this.overriddenCommissionAfterDiscount) return this.overriddenCommissionAfterDiscount
      return this.commissionAfterDiscount
    },
    salesOrAcquisitionPriceLabel () {
      return this.initialValues.transaction_type === 2 ? 'verkoopprijs' : 'overnameprijs'
    },
    salesOrAcquisitionPriceLabelCapitalized () {
      return this.salesOrAcquisitionPriceLabel.charAt(0).toUpperCase() + this.salesOrAcquisitionPriceLabel.slice(1) + ' (€)'
    },
    agreementLabel () {
      return this.initialValues.transaction_type === 2 ? 'Verkoopovereenkomst' : 'Overnameovereenkomst'
    },
    requiredPercentageSum () {
      if (this.coMandatePercentageType === 'commission_amount_split') {
        return 100
      } else if (this.coMandatePercentageType === 'commission_percentage_split') {
        return parseFloat(this.values?.commission_percentage_out) || parseFloat(this.values?.transaction_data?.[0].commission_percentage_in)
      }
      return 0
    },

    commissionAmountCollaborator () {
      if (this.overriddenCommissionAfterDiscount) {
        return (this.overriddenCommissionAfterDiscount * (parseFloat(this.values.co_mandate_collaborator_percentage_out) / 100)).toFixed(2)
      }
      if (this.coMandatePercentageType === 'commission_amount_split') {
        return (this.commissionAfterDiscount * (parseFloat(this.values.co_mandate_collaborator_percentage_out) / 100)).toFixed(2)
      } else if (this.coMandatePercentageType === 'commission_percentage_split') {
        return (parseFloat(this.values.transaction_price_out) * (parseFloat(this.values.co_mandate_collaborator_percentage_out) / 100)).toFixed(2) - this.commissionFixDiscountCollaborator
      }
      return 0
    },

    commissionAmountOther () {
      if (this.overriddenCommissionAfterDiscount) {
        return (this.overriddenCommissionAfterDiscount * (parseFloat(this.values.co_mandate_other_percentage_out) / 100)).toFixed(2)
      }
      if (this.coMandatePercentageType === 'commission_amount_split') {
        return (this.commissionAfterDiscount * (parseFloat(this.values.co_mandate_other_percentage_out) / 100)).toFixed(2)
      } else if (this.coMandatePercentageType === 'commission_percentage_split') {
        return (parseFloat(this.values.transaction_price_out) * (parseFloat(this.values.co_mandate_other_percentage_out) / 100)).toFixed(2) - this.commissionFixDiscountOther
      }
      return 0
    },

    commissionFixDiscountCollaborator () {
      if (!this.values?.commission_fix_discount) return 0
      if (this.values?.co_mandate_collaborator_percentage_out === null) return 0
      const commissionPercentageCollaborator = parseFloat(this.values?.co_mandate_collaborator_percentage_out)
      const commissionPercentage = parseFloat(this.values?.transaction_data[0]?.commission_percentage_in)
      if (!commissionPercentage) return 0
      const commissionFixDiscount = parseFloat(this.values.commission_fix_discount)
      const result = ((commissionPercentageCollaborator / commissionPercentage) * commissionFixDiscount).toFixed(2)
      return result
    },
    commissionFixDiscountOther () {
      if (!this.values?.commission_fix_discount) return 0
      const commissionPercentageOther = parseFloat(this.values?.co_mandate_other_percentage_out)
      const commissionPercentage = parseFloat(this.values?.transaction_data[0]?.commission_percentage_in)
      if (!commissionPercentage) return 0
      const commissionFixDiscount = parseFloat(this.values?.commission_fix_discount)
      return ((commissionPercentageOther / commissionPercentage) * commissionFixDiscount).toFixed(2)
    },
    hasSuspensiveCondition () {
      return this.values?.transaction_data?.[0].ovab
    }
  },
  watch: {
    newIntakeCommission (value) {
      if (!value) return false
      this.$set(this.values, 'commission_fix_in', value?.toFixed(2))
    },
    commissionAfterDiscount (value) {
      if (!value) return false
      this.$set(this.values, 'commission_fix_out', value?.toFixed(2))
      if (this.values.sold_by) this.setCoMandateCommissions()
    }
  },
  created () {
    this.setInitialValues()
  },
  methods: {
    currency,
    percentage,
    ...mapActions('properties', ['loadProperty']),

    advancePaymentAccountChanged (value) {
      // We only want to set it to false when the advance payment account is not Dewaele.
      // We don't want to set it to true again, incase the user changes the advance payment account back to Dewaele,
      // as they may have changed their selection regarding the checkbox.
      if (parseInt(value) === 1) return value
      this.$set(this.values, 'should_deduct_sales_fee_from_advance_payment', false)
    },
    moveToStep2 (data) {
      data.transaction_price_in = this.newPrice
      data.involvements = this.$refs.transactionInvolvements ? this.$refs.transactionInvolvements.getInvolvements() : []
      this.$emit('finished', data, this.CURRENT_STEP)
      return data
    },

    setInitialValues (response) {
      const { transaction_data, agreements_folder_id, bid_amount, ...values } = this.initialValues
      values.should_deduct_sales_fee_from_advance_payment = true // default set to true
      values.third_party_account = this.thirdPartyAccounts?.[0]?.value
      if (transaction_data) {
        values.transaction_data = [transaction_data]
        values.co_mandate_percentage_type_in = transaction_data.co_mandate_percentage_type_in
        values.co_mandate_percentage_type_out = transaction_data.co_mandate_percentage_type_in
        values.co_mandate_collaborator_percentage_in = transaction_data.co_mandate_collaborator_percentage_in
        values.co_mandate_other_percentage_in = transaction_data.co_mandate_other_percentage_in
        values.co_mandate_collaborator_percentage_out = transaction_data.co_mandate_collaborator_percentage_in
        values.co_mandate_other_percentage_out = transaction_data.co_mandate_other_percentage_in
      }
      if (bid_amount) values.transaction_price_out = bid_amount
      this.folderId = agreements_folder_id
      this.values = values
      return response
    },

    soldByChanged (value) {
      if (value === 'co-broker') {
        this.$set(this.values, 'commission_discount', '')
        this.$set(this.values, 'should_deduct_sales_fee_from_advance_payment', false)
      }
      this.setCoMandateCommissions()
    },

    handleTransactionPriceOutChange (value) {
      if (!this.values.sold_by) return
      this.setCoMandateCommissions()
    },

    validMandatePercentage ({ getFormValues }) {
      const { co_mandate_collaborator_percentage_out, co_mandate_other_percentage_out } = getFormValues()

      if (!co_mandate_collaborator_percentage_out || !co_mandate_other_percentage_out) return true
      const sum = parseFloat(co_mandate_collaborator_percentage_out) + parseFloat(co_mandate_other_percentage_out)
      return sum === parseFloat(this.requiredPercentageSum)
    },

    handleCommissionDiscountChange (value) {
      if (!this.values.sold_by) return
      this.setCoMandateCommissions()
    },

    handleCommissionDiscountPercentageChange (value) {
      if (!this.hasCoBroker) return

      if (this.coMandatePercentageType === 'commission_amount_split') return

      const currentCommissionPercentage = this.changedPercentage || parseFloat(this.values?.transaction_data?.[0]?.commission_percentage_in)
      const currentCollaboratorPercentage = parseFloat(this.values?.co_mandate_collaborator_percentage_out)
      const currentOtherPercentage = parseFloat(this.values?.co_mandate_other_percentage_out)

      const proportionCollaborator = currentCollaboratorPercentage / currentCommissionPercentage
      const proportionOther = currentOtherPercentage / currentCommissionPercentage

      const newCollaboratorPercentage = (value * proportionCollaborator).toFixed(2)
      const newOtherPercentage = (value * proportionOther).toFixed(2)
      this.$set(this.values, 'co_mandate_collaborator_percentage_out', newCollaboratorPercentage)
      this.$set(this.values, 'co_mandate_other_percentage_out', newOtherPercentage)

      this.changedPercentage = value
    },

    getCommissionCoMandateDewaele () {
      const transaction = this.values.transaction_data?.[0]
      if (!transaction) return

      const coMandatePercentageCollaborator = this.values.co_mandate_collaborator_percentage_out
      return this.commissionAfterDiscountForCalculations * (parseFloat(coMandatePercentageCollaborator) / 100)
    },
    getCommissionCoMandateOther () {
      const transaction = this.values.transaction_data?.[0]
      if (!transaction) return

      const coMandatePercentageCollaborator = this.values.co_mandate_collaborator_percentage_out
      const coMandatePercentageOther = this.values.co_mandate_other_percentage_out

      let percentage = coMandatePercentageCollaborator
      if (this.values.sold_by === 'dewaele') percentage = coMandatePercentageOther
      if (!percentage) return 0

      return this.commissionAfterDiscountForCalculations * (parseFloat(percentage) / 100)
    },

    handleCommissionOverrideToggle () {
      // Percentage-based discount is not possible if the commission is being overridden, reset the values
      if (this.values.commission_discount === 'percentage') {
        this.$set(this.values, 'commission_discount', '')
        this.$set(this.values, 'commission_percentage_out', null)
      }
      if (!this.values.sold_by) return // Only proceed if it's a co-mandate transaction
      if (this.values.override_commission) {
        if (this.values.co_mandate_percentage_type_in === 1) {
          const collaboratorPercentage = this.values.co_mandate_collaborator_percentage_in
          const otherPercentage = this.values.co_mandate_other_percentage_in
          const commissionPercentageIn = this.values.transaction_data[0].commission_percentage_in
          const collaboratorPercentageForFixValueCommission = ((collaboratorPercentage / commissionPercentageIn) * 100).toFixed(2)
          const otherPercentageForFixValueCommission = ((otherPercentage / commissionPercentageIn) * 100).toFixed(2)
          this.$set(this.values, 'co_mandate_percentage_type_out', 0)
          this.$set(this.values, 'co_mandate_collaborator_percentage_out', collaboratorPercentageForFixValueCommission)
          this.$set(this.values, 'co_mandate_other_percentage_out', otherPercentageForFixValueCommission)
        }
      } else {
        if (this.values.co_mandate_percentage_type_in === 1) {
          this.$set(this.values, 'co_mandate_percentage_type_out', 1)
          this.$set(this.values, 'co_mandate_collaborator_percentage_out', this.values.co_mandate_collaborator_percentage_in)
          this.$set(this.values, 'co_mandate_other_percentage_out', this.values.co_mandate_other_percentage_in)
        }
      }
    },

    setCoMandateCommissions () {
      this.$set(this.values, 'commission_co_mandate_dewaele', this.getCommissionCoMandateDewaele())
      this.$set(this.values, 'commission_co_mandate_other', this.getCommissionCoMandateOther())
    },

    cancelDossier () {
      this.$emit('cancel')
    },

    async createAgreementsFolder () {
      const response = await createPropertyFileFolder(this.propertyId, { name: 'Overeenkomsten' })
      // We don't want to call this method again and again, so we store the value of the folderId
      this.folderId = response.data.id
      return response.data.id
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const file_type_key = this.values.transaction_type === 2 ? 'signed_sales_agreement' : 'signed_acquisition_agreement'
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folder_id = this.folderId || await this.createAgreementsFolder()
        progress(75)
        const payload = { key, filename, file_type_key, folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)

        const parsedFile = parseFileObj(response.data)
        return [parsedFile]
      } catch (error) {
        this.$formulate.handle(error, 'salesAgreementStep1')
      }
    }
  }
}
</script>
