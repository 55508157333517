<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="salesAgreementStep2"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="moveToStep3"
  >

    <EntityContactsFormPartial
      ref="entityOwners"
      contact-role="owner"
      :form-values="values"
      :property-id="propertyId"
      :custom-labels="{
        contactsInputLabel: 'Verkopers',
        addContact: '+ Verkoper toevoegen'
      }"
      :show-disclaimer="false"
      :ask-google-review="true"
      :contact-validations="[
        ['bail'],
        ['required'],
        ['min', 1, 'length']
      ]"
    />

    <div class="tw-my-10 md:tw-grid tw-grid-cols-3">
      <!-- We use div here because setting the grid on the element class would make the clear button jump out of the input -->
      <FormulateInput
        type="autocomplete"
        auto-complete-type="contact"
        name="notary_of_owners"
        label="Notaris verkopers"
        placeholder="Selecteer contact"
        :validation="soldByCoBroker ? 'optional' : 'required'"
        :show-required-label="!soldByCoBroker"
        data-lpignore="true"
        outer-class="tw-my-0"
      />
    </div>

    <EntityContactsFormPartial
      ref="entityBuyers"
      contact-role="buyer"
      :form-values="values"
      :property-id="propertyId"
      :show-disclaimer="false"
      :ask-google-review="true"
      :contact-validations="[
        ['bail'],
        [soldByCoBroker ? 'optional' : 'required'],
        ['min', 1, 'length']
      ]"
    />

    <div class="tw-my-10 md:tw-grid tw-grid-cols-3">
      <!-- We use div here because setting the grid on the element class would make the clear button jump out of the input -->
      <FormulateInput
        type="autocomplete"
        auto-complete-type="contact"
        name="notary_of_buyers"
        label="Notaris kopers"
        placeholder="Selecteer contact"
        :validation="soldByCoBroker ? 'optional' : 'required'"
        :show-required-label="!soldByCoBroker"
        data-lpignore="true"
        outer-class="tw-my-0"
      />
    </div>

    <FormulateErrors class="tw-my-4 tw-text-right" />

    <div class="tw-mt-8 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end">
      <FormulateInput
        type="button"
        :input-class="['tw-bg-gray-500']"
        outer-class="tw-my-0"
        @click="goBackToStep1"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige stap
      </FormulateInput>
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        outer-class="tw-my-0"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende stap
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import EntityContactsFormPartial from '@/components/properties/EntityContactsFormPartial'

export default {
  name: 'WizardSalesAgreementStep2',
  components: {
    EntityContactsFormPartial
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    initialValues: {
      type: Object,
      required: true
    },
    stepValues: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  constants: {
    CURRENT_STEP: 2
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    soldByCoBroker () {
      return this.stepValues.step1?.sold_by === 'co-broker'
    }
  },
  created () {
    this.$set(this.values, 'notary_of_buyers', this.initialValues.notary_of_buyers)
    this.$set(this.values, 'notary_of_owners', this.initialValues.notary_of_owners)
  },
  methods: {
    goBackToStep1 () {
      this.$emit('go-back', this.CURRENT_STEP)
    },
    async moveToStep3 (data) {
      try {
        const [buyersResult, ownersResult] = await Promise.all([
          this.$refs.entityBuyers.submit(),
          this.$refs.entityOwners.submit()
        ])
        data.google_review_contact_ids = [...buyersResult.google_review_contact_ids, ...ownersResult.google_review_contact_ids]
        this.$emit('finished', data, this.CURRENT_STEP)
        return [buyersResult, ownersResult]
      } catch (error) {
        this.$formulate.handle(error, 'salesAgreementStep2')
        throw error
      }
    }
  }
}
</script>
