var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"name":"rentalAgreementStep2","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.moveToStep3},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('EntityContactsFormPartial',{ref:"entityOwners",attrs:{"contact-role":"owner","form-values":_vm.values,"property-id":_vm.propertyId,"show-disclaimer":false,"contact-validations":[
      ['bail'],
      ['required'],
      ['min', 1, 'length']
    ]}}),_c('EntityContactsFormPartial',{ref:"entityRenters",staticClass:"tw-mt-4 tw-mb-8",attrs:{"contact-role":"renter","form-values":_vm.values,"property-id":_vm.propertyId,"show-disclaimer":false,"contact-validations":[
      ['bail'],
      ['required'],
      ['min', 1, 'length']
    ]}}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-gray-500'],"outer-class":"tw-my-0"},on:{"click":_vm.goBackToStep1}},[_c('i',{staticClass:"fas tw-mr-2 fa-arrow-left"}),_vm._v(" Vorige stap ")]),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-my-0"}},[_c('i',{class:[
          'fas tw-mr-2',
          isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
        ]}),_vm._v(" Volgende stap ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})}
var staticRenderFns = []

export { render, staticRenderFns }