<template>
  <Modal ref="modal" title="Transacties gelinkt aan dit pand">
    <div>
      <Loader v-if="loading"></Loader>
      <div v-else>
        <div v-if="transactions && transactions.length">
          <ul>
            <li :key="transaction.id" v-for="transaction in transactions">
              <a :href="`/transaction/view/${transaction.id}`">ID {{ transaction.id }}: {{ transaction.reference }}</a>
            </li>
          </ul>
        </div>
        <div v-else-if="property.status === 1">
          <p>Dit is een prospectpand. Er wordt automatisch een transactie aangemaakt wanneer dit pand in portefeuille wordt opgenomen.</p>
        </div>
        <div v-else>
          <p>Er zijn nog geen transacties gekoppeld aan dit pand.</p>
          <p>
            <button
              v-if="property.status !== 1"
              type="button"
              class="btn btn-primary"
              @click="createNewTransaction"
            >
              Nieuwe transactie aanmaken
            </button>
          </p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../iam/Modal'
import { startLoadingModal, errorModal, successModal } from '@/modalMessages'
import Loader from '../iam/Loader'
import { createTransaction, getTransactions } from '@/services/transactions'

export default {
  name: 'TransactionModal',
  components: { Loader, Modal },
  props: ['property'],
  data () {
    return {
      loading: false,
      transactions: []
    }
  },
  methods: {
    async show () {
      if (this.property) {
        this.loading = true
        this.$refs.modal.show()
        const response = await getTransactions({ property: this.property.id })
        this.transactions = response.data?.results
        this.loading = false
      }
    },
    hide () {
      this.$refs.modal.hide()
    },
    async createNewTransaction () {
      try {
        startLoadingModal('De transactie wordt aangemaakt...')
        const payload = { property: this.property.id }
        const response = await createTransaction(payload)
        successModal('De transactie is succesvol aangemaakt')
        window.location = `/transaction/view/${response?.data?.id}`
        return response
      } catch (error) {
        const errorCode = error.response?.data?.code?.[0]

        if (errorCode === 'TRANSACTION_FOR_PROPERTY_ALREADY_EXISTS') {
          errorModal('Aan dit pand is al een transactie gekoppeld.')
        } else if (errorCode === 'PROPERTY_HAS_NO_OFFICE') {
          errorModal('Koppel een kantoor aan dit pand om een transactie te kunnen aanmaken.')
        } else {
          errorModal('Er is iets misgegaan bij het aanmaken van de transactie.')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
