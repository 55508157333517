<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="form"
    name="finishTransaction"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <Modal ref="modal" title="Transactie voltooien">
      <p>
        De pandstatus wijzigt naar "Uit portefeuille" en de status van de transactie zal automatisch op "Beëindigd" worden gezet. Gelieve "Datum opzeg" en "Datum einde mandaat" hier op te geven.
      </p>

      <FormulateInput
        v-if="transactions.length > 1"
        type="select"
        name="transaction"
        label="Selecteer transactie om te voltooien"
        placeholder="Selecteer transactie"
        :options="transactionOptions"
        validation="required"
      />
      <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
        <FormulateInput
          type="date"
          name="termination_letter_reception_date"
          label="Datum ontvangst opzegbrief"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          name="mandate_end_date"
          label="Datum einde opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
      </div>

      <template #footer>
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Beëindigd
          </FormulateInput>
          <FormulateInput
            type="button"
            data-dismiss="modal"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i class="fa fa-times tw-mr-2"/> Annuleren
          </FormulateInput>
        </div>
      </template>
    </Modal>
  </FormulateForm>
</template>

<script>
import Modal from '@/components/iam/Modal'

import { getProperty, updateProperty } from '@/services/properties'
import { updateTransaction } from '@/services/transactions'

export default {
  name: 'PropertyFinishTransaction',
  components: {
    Modal
  },
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    },
    transactions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    transactionOptions () {
      return this.transactions.map(({ id, referentie }) => {
        return {
          value: id,
          label: `ID ${id}: ${referentie}`
        }
      })
    }
  },
  methods: {
    async show () {
      const response = await getProperty(this.propertyId)
      // Pre-fill the values in the form
      this.$set(this.form, 'termination_letter_reception_date', response?.data?.termination_letter_reception_date)
      this.$set(this.form, 'mandate_end_date', response?.data?.mandate_end_date)
      return this.$refs.modal.show()
    },
    async submit (data = {}) {
      try {
        const { transaction = this.transactions[0]?.id, ...payload } = data
        const response = await Promise.all([
          updateProperty(this.propertyId, payload),
          updateTransaction(transaction, { transaction_end_status: 'T', transaction_end_date: data.mandate_end_date })
        ])
        this.$refs.modal.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'finishTransaction')
      }
    }
  }
}
</script>
