<template>
  <BaseModal ref="cancelDossierModal" title="Verkoop annuleren" max-width="tw-max-w-lg">
    <FormulateForm
      #default="{ isLoading }"
      name="cancelDossierForm"
      @submit="cancelDossier"
    >
      <p class="tw-font-bold">Gelieve hierbij te bevestigen dat de verkoop van dit dossier niet doorgaat.</p>
      <p class="tw-font-bold">De volgende acties worden uitgevoerd:</p>
      <ul class="tw-ml-4 tw-mb-4 tw-list-disc">
        <li>De fase van het dossier wordt teruggezet naar de bodregistratie.</li>
        <li>Het geaccepteerde bod wordt op niet-geaccepteerd gezet.</li>
        <li>De status van het dossier wordt op 'te koop' gezet.</li>
        <li>De verkooplead die als deal werd aangeduid, wordt gebreakt.</li>
      </ul>
      <p class="tw-font-bold">De volgende gegevens worden gewist:</p>
      <ul class="tw-ml-4 tw-list-disc">
        <li>De uitgaande zijde van de gelinkte transactie</li>
        <li>Het verwachte voorschot</li>
        <li>De ondertekendatum van de onderhandse verkoopovereenkomst</li>
        <li>De kopers</li>
        <li>De notaris van de kopers</li>
        <li>De vooropgestelde aktedatum</li>
        <li>De stickers "Verkocht" of "Waarschijnlijk verkocht"</li>
      </ul>
      <div v-if="hasInvoicedCommissionFee" class="tw-mt-4 tw-font-bold">
        Opgelet: het ereloon werd reeds gefactureerd. Dit moet manueel worden rechtgezet op de commissielijst van de makelaar.
        De factuur moet gecrediteerd worden door een superuser.
      </div>
      <div class="tw-flex tw-justify-end">
        <FormulateInput type="submit" :outer-class="['tw-mb-0']">
          <i
            :class="[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-check'
            ]"
          />
          Bevestigen
        </FormulateInput>
      </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { cancelPropertyDossier } from '@/services/properties'
import { hasInvoicedCommissionFee } from '@/services/invoices'
import { mapActions } from 'vuex'
import { successModal, warningModal } from '@/modalMessages'

export default {
  name: 'PropertyCancelDossierModal',
  props: {
    propertyId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      hasInvoicedCommissionFee: false
    }
  },
  methods: {
    ...mapActions('properties', ['loadProperty']),
    async show () {
      const response = await hasInvoicedCommissionFee(this.propertyId)
      this.hasInvoicedCommissionFee = response?.data?.has_invoiced_commission_fee
      this.$refs.cancelDossierModal.show()
      return response
    },
    async cancelDossier () {
      try {
        const response = await cancelPropertyDossier(this.propertyId)
        await this.loadProperty(this.propertyId)
        this.$emit('dossier-cancelled')
        this.$refs.cancelDossierModal.hide()
        successModal('De verkoop van dit dossier werd geannuleerd.')
        return response.data
      } catch (error) {
        if (error.response?.data?.code === 'PUBLISHED_COMMISSION_LIST') {
          return warningModal('De transactie gelinkt aan dit pand is onderdeel van een gepubliceerde commissielijst. De verkoop kan niet ongedaan worden gemaakt.')
        }
        this.$formulate.handle(error, 'cancelDossierForm')
        throw error
      }
    }
  }
}
</script>
