var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{attrs:{"name":"rentalAgreementStep3","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-mb-8 tw-pb-4 tw-border-b"},[_c('h2',[_vm._v("Pandstatus")]),_c('FormulateInput',{attrs:{"name":"status","type":"radio","options":[
        { value: 3, label: 'Te huur'},
        { value: 11, label: 'Verhuurd'}
      ],"input-class":['tw-my-1'],"element-class":['tw-flex tw-flex-row tw-flex-wrap tw-gap-4 tw-items-center'],"outer-class":"md:tw-w-1/3 tw-my-0"}}),_c('FormulateInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPublishAsDewaeleReference),expression:"showPublishAsDewaeleReference"}],attrs:{"name":"publish_as_dewaele_reference","type":"checkbox","label":"Publiceren/behouden als referentie op Dewaele.com","outer-class":"tw-mt-2 tw-mb-4"}})],1),_c('h2',[_vm._v("Openstaande leads")]),_c('WizardLeadCount',{staticClass:"tw-mb-4",attrs:{"propertyId":_vm.propertyId}}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-mt-4 tw-flex tw-flex-wrap tw-gap-4 tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-gray-500'],"outer-class":"tw-my-0"},on:{"click":_vm.goBackToStep2}},[_c('i',{staticClass:"fas tw-mr-2 fa-arrow-left"}),_vm._v(" Vorige stap ")]),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-my-0"}},[_c('i',{class:[
          'fas tw-mr-2',
          isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
        ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})}
var staticRenderFns = []

export { render, staticRenderFns }